import React, { useRef } from "react";

import SEO from "../../components/seo";
import Layout from "../../components/layout";
import ProjectHero from "../../modules/hero/project-hero";
import CoverSection from "../../components/project/cover-section";
import ProjectDetails from "../../components/project/project-details";
import Aassociate1 from "../../images/associate/1.jpeg";
import Aassociate3 from "../../images/associate/3.jpeg";
import Aassociate4 from "../../images/associate/4.jpeg";
import Aassociate5 from "../../images/associate/5.jpeg";
import Aassociate6 from "../../images/associate/6.jpeg";
import Aassociate7 from "../../images/associate/7.jpeg";
import FooterMapDetails from "../../components/footer/footer-map-details";

const images = [
  { src: Aassociate3, title: "project associate" },
  { src: Aassociate4, title: "project associate" },
  { src: Aassociate5, title: "project associate" },
  { src: Aassociate6, title: "project associate" },
  { src: Aassociate7, title: "project associate" },
];

const ProjectTemplate = ({ location }) => {
  const seo = {
    pageUrl: "/projects/associate-com",
    pageTitle: "Associate.com | Vello | A full-service creative agency.",
    indexibility: "Indexable",
    metaDescription: "Associate.com | Vello | A full-service creative agency.",
  };

  const pageLayoutRef = useRef();
  const coverSectionUnique = `associate-section`;

  const footerDate = {
    location: location,
    menu: [
      {
        text: "Start a project",
        link: "/start-a-project",
      },
      {
        text: "Our Services",
        link: "/services",
      },
      {
        text: "Our Work",
        link: "/work",
      },
    ],
    description: `
    We define and develop solutions carefully, skillfully, and beautifully. Powered by a solid team of talented professionals, we collaborate globally to craft the best solutions for you.`,
  };

  return (
    <div ref={pageLayoutRef}>
      <SEO data={seo} location={location} />
      <Layout location={location}>
        <ProjectHero
          title={"Associate.com"}
          client={"Associate.com"}
          output={"Designs"}
          publishDate={"2021"}
          externalLink={"https://www.associate.com/"}
          coverSectionUnique={coverSectionUnique}
          pageLayoutRef={pageLayoutRef}
        />
        <CoverSection
          coverImage={Aassociate1}
          coverSectionUnique={coverSectionUnique}
        />

        <ProjectDetails
          overview={
            "We make it easy and fast to create or join an Internet Association, which makes it easy for anyone to organise and govern projects, anywhere in the world, via the world wide web. We are boldly unlocking new possibilities in the areas of global cooperation and organisational frameworks, hence, Associate and its members could make traditional incorporation unnecessary, or even obsolete. The Associate Mission is to transform the global process of incorporation so that it is accessible and achievable worldwide, via the internet, providing integrated banking, accounting, and infrastructure available on-demand, for free."
          }
          ourSolution={
            "From the client requirements, we went through their whole platform UI and UX and applied our design methodology. User research, UX flows, information architecture, graphic, and customer journey."
          }
          highlightedText={
            "As we continue to work with Associate.com, we're learning more about their users and how to best design for them."
          }
          images={images}
          nextPageLink={"/projects/avnt"}
          pageLayoutRef={pageLayoutRef}
        />
        <FooterMapDetails {...footerDate} pageLayoutRef={pageLayoutRef} />
      </Layout>
    </div>
  );
};

export default ProjectTemplate;
